import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { Tooltip } from "@mui/material";
import { usePlenaWallet } from "plena-connect-dapp-sdk";
import { useAccount, useDisconnect } from "wagmi";
import S from "./header.styled";
import USDTLogo from "../../../assets/images/usdtLogo.svg";
import { onrampInstance } from "../../..";
import { shortenAddress } from "../../../services/plena/utils";
import { disconnectWallet, withdrawPredictTokens, withdrawTokens } from "../../../store/slices/wallet.actions";
import { PRIMARY } from "../../../utils/constants";
import {
  useDraw100kNumber,
  useEnteredDraw,
  useHasParticipant,
  useIsAppLoading,
  useIsReady,
  useIsWhitelisted,
  useTicketsSold,
  useWinnerOverlay,
} from "../../../utils/hooks/selector";
import { useAppDispatch } from "../../../utils/hooks/state";
import { Button } from "../../../utils/widgets";
import HeaderSkeleton from "../../../utils/widgets/skeletons/header-skeleton";
import NewEarnedTokensPopover from "../../popovers/NewEarnedTokensPopover";
import ReferPopover from "../../popovers/ReferPopover";
import MyEarnedToken from "./MyEarnedToken";
import MyEarnings from "./MyEarnings";
import MyUSDTBalance from "./MyUSDTBalance";
import UserDetailsCard from "./UserDetailsCard";

const HeaderRightContainer: FC = () => {
  //constructors
  const dispatch = useAppDispatch();
  const { openModal, closeConnection, walletAddress } = usePlenaWallet();
  //state values
  const enteredDraw = useSelector(useEnteredDraw);
  const winnerOverlay = useSelector(useWinnerOverlay);
  const ticketsSold = useSelector(useTicketsSold);
  const isAppLoading = useSelector(useIsAppLoading);
  const draw100kNumber = useSelector(useDraw100kNumber);
  const isWhitelisted = useSelector(useIsWhitelisted);

  const [referPopover, setReferPopover] = useState<HTMLButtonElement | null>(null);
  const [earnTokenPopover, setEarnTokenPopover] = useState<HTMLDivElement | null>(null);
  const { isConnected: isWalletConnected } = useAccount();

  //constants
  const hundredKCompleted = Boolean(ticketsSold !== 0 && ticketsSold % draw100kNumber === 0);
  const hasSkeleton = false;

  //On WinnerTimerModal or isAppLoading Open
  useEffect(() => {
    if (winnerOverlay || isAppLoading) {
      setEarnTokenPopover(null);
      setReferPopover(null);
    }
  }, [winnerOverlay, isAppLoading]);

  //functions
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setReferPopover(event.currentTarget);
  };

  const handleClose = () => {
    setReferPopover(null);
  };

  const handleClaimBtnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setEarnTokenPopover(event.currentTarget);
  };

  const handleClaim = () => {
    dispatch(withdrawTokens());
  };

  const handlePredictClaim = () => {
    dispatch(withdrawPredictTokens());
  };

  const handleEarnedPopoverClose = () => {
    setEarnTokenPopover(null);
  };

  return (
    <S.HeaderRightContainer>
      {hasSkeleton ? (
        <HeaderSkeleton />
      ) : (
        <>
          {!enteredDraw && isWalletConnected && isWhitelisted && <S.WhiteListedUser>{"(Whitelisted User)"}</S.WhiteListedUser>}
          {enteredDraw && <MyEarnings />}
          {/* {isWalletConnected && <MyUSDTBalance />} */}
          {!enteredDraw && isWalletConnected && <MyUSDTBalance />}
          <S.BuyButton
            onClick={() => {
              onrampInstance.show();
            }}
          >
            Buy
            <img className="img" src={USDTLogo} alt="usdt" />
            USDT
          </S.BuyButton>
          {enteredDraw && (
            <S.ClaimTokenContainer>
              <MyEarnedToken onClick={handleClaimBtnClick} />
              {earnTokenPopover && (
                <NewEarnedTokensPopover
                  id={Boolean(earnTokenPopover) ? "earned-token" : undefined}
                  onClose={handleEarnedPopoverClose}
                  isOpen={earnTokenPopover}
                  handleClaim={handleClaim}
                  handlePredictClaim={handlePredictClaim}
                />
              )}
            </S.ClaimTokenContainer>
          )}
          {isWalletConnected && <UserDetailsCard />}

          {enteredDraw ? (
            !hundredKCompleted ? (
              <S.ReferContainer data-tut="tour-user-refer">
                <Button buttonType={PRIMARY} onClick={(e) => handleClick(e)} customStyle={{ height: "2rem", padding: "0.3125rem 1.25rem" }}>
                  Refer&nbsp;
                  <KeyboardArrowDownIcon />
                </Button>
                {referPopover && <ReferPopover id={Boolean(referPopover) ? "refer-by" : undefined} isOpen={referPopover} onClose={handleClose} />}
              </S.ReferContainer>
            ) : null
          ) : (
            <button className="connect-wallet" onClick={openModal}>
              {!walletAddress ? "Connect Wallet" : shortenAddress(walletAddress)}
            </button>
          )}
          {walletAddress && (
            <Tooltip title="Disconnect" arrow data-tut="tour-wallet-logout">
              <S.LogoutButton
                onClick={() => {
                  dispatch(disconnectWallet());
                  closeConnection();
                }}
              >
                <LogoutIcon />
              </S.LogoutButton>
            </Tooltip>
          )}
        </>
      )}
    </S.HeaderRightContainer>
  );
};

export default HeaderRightContainer;
