import { FC } from "react";
import "react-toastify/dist/ReactToastify.css";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { bsc, polygonMumbai } from "wagmi/chains";
import "../assets/css/app.css";
import AppRouters from "../routes/AppRouters";
import CustomProvider from "../utils/providers";
import { PlenaWalletProvider } from "plena-connect-dapp-sdk";

const currentChain = Number(process.env.REACT_APP_CURRENT_CHAINID);

const projectId = process.env.REACT_APP_PROJECT_ID || "";

const metadata = {
  name: "Yearn Together",
  description: "The world's first ever gaming-enabled IDO",
  url: "https://app.yearntogether.com/",
  icons: [""],
};

const chains = currentChain === 56 ? [bsc] : [polygonMumbai];

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({ wagmiConfig, projectId, chains });

const config = {
  dappToken: "91651531fc0ff6f89808b72c7ca0fcda6a9816a225e33f4b226e5bfdadccf776007dee0a61aa8bfd8f32ceed5c3374da4b820f51b1dd1829c441aaa4eee83891",
  dappId: "cm61ds5dotv8m80olbig",
  bridgeUrl: "connect.plena.finance",
};


const App: FC = () => {
  return (
    <CustomProvider>
      <PlenaWalletProvider config={config}>

      <WagmiConfig config={wagmiConfig}>
        <AppRouters />
      </WagmiConfig>
      </PlenaWalletProvider>
    </CustomProvider>
  );
};

export default App;
