import { BigNumber, ethers } from "ethers";
import { usdtTokenAddress } from "../../utils/providers/platforms";
import erc20ABI from "../ethereum/build/IERC20.json";
import mainABI from "../ethereum/build/abi.json";
import { CONTRACT } from "../ethereum/contract/web3";

export const getApprovalData = (amount: any) => {
  const approveContract = new ethers.utils.Interface(erc20ABI.abi);
  const approveData = approveContract.encodeFunctionData("approve", [CONTRACT[56], amount]);
  return approveData;
};

export const getDepositData = (referrer: string, amount: any) => {
  const lendContract = new ethers.utils.Interface(mainABI.abi);

  const lendData = lendContract.encodeFunctionData("deposit", [Number(referrer), amount]);

  return lendData;
};

export namespace PlenaERC {
  export const bscProvider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.binance.org/");
  console.log({ bscProvider });
  export const USDTContract = new ethers.Contract(usdtTokenAddress[56] || "0x00", erc20ABI.abi, bscProvider);
  console.log({ USDTContract });
  export const getBalance = async (address: string) => {
    try {
      const balance = await USDTContract.balanceOf(address);
      const ethBalance = ethers.utils.formatEther(balance.toString());
      return {
        full: ethBalance,
        deci: Number(ethBalance).toFixed(2).toString(),
      };
    } catch (e) {
      console.log({
        e,
      });
    }
  };
}
